<!-- ########################## <Template> ########################## -->
<template lang="">

    <Header></Header>
    <div class="pageTitle" > 
        <font-awesome-icon icon="caret-right" /> 
        Gestion des Imports OMPIC
    </div>
    <Dialog header="Importer un fichier zip." :visible="visible" @update:visible="hideHandler"  :style="{ width: '50rem' }">
        <ProgressBar mode="indeterminate" style="height: 6px" v-if="isImporting"></ProgressBar>
        <!-- Ne doit pas dépasser 8MB -->
        <br/>
        <div style="display:flow">
            <div class="col">
                Année:<br/><Calendar v-model="yearInput" view="year" dateFormat="yy" />
            </div>
            <div class="col">
                Fichier:<FileUpload accept=".zip" :showUploadButton="false" ref="fileUpload" :auto="true" :showCancelButton="true" chooseLabel="Importer Zip" mode="advanced" name="demo[]" :customUpload="true" @uploader="assignEventGlobal($event)" icon="pi pi-upload" class="p-button-outlined p-button-success" @clear="eventFileUpload = null" @remove="eventFileUpload = null"/>
            </div>
        </div>
        <br/>
        <div style="position: absolute;bottom: 5px;right: 25px;">
            <P-Button :disabled="!eventFileUpload"  label="Importer" icon="pi pi-upload" class="p-button-success p-mr-2" @click="onTemplatedUpload(eventFileUpload)" />
        </div>
    </Dialog>

    <div class="pageBodyContainer"> 
        <P-Button  label="Ajout d'un bilan Ompic" icon="pi pi-upload" class="p-button-outlined p-button-success m-2 p-mr-2" @click="eventFileUpload = null;visible = true" />
        <div>
            <div class="card">
                <DataTable ref="dt" :value="gridData"  dataKey="id" 
                    :filters="filters"
                    responsiveLayout="scroll" class="p-datatable-sm" 
                    v-model:expandedRows="expandedRows"
                    @rowExpand="onRowExpand" showGridlines >
                    <!-- <Column selectionMode="multiple" style="width: 3rem" :exportable="false"></Column> -->
                    <Column expander style="width: 5rem" />
                    <!-- <Column v-for="col in columnsGrid" :header="col" :field="col" :exportable="false" style="min-width:12rem">
                    </Column> -->
                    <Column header="Annee" field="annee"></Column>
                    <Column header="Informations globales">
                        <template #body="slotProps">
                            <div>Etat     : <Tag v-if="slotProps.data.statutImportGlobal=='1'" value="Ok" severity="success" ></Tag><Tag v-else severity="danger" value="Erreur"></Tag></div>
                            <div>Crée par : <b>{{ slotProps.data.createur }}</b></div>
                            <div>Crée le  : <b>{{ new Date(slotProps.data.dateCreation).toJSON().replaceAll('T',' ').substring(0,19)}}</b></div>
                            <div>Fini le  : <b>{{ new Date(slotProps.data.dateFinImport).toJSON().replaceAll('T',' ').substring(0,19)}}</b></div>
                            <div>Durée    : <b>{{ slotProps.data.dureeImportSecondes }} s</b></div>
                        </template>
                    </Column>
                    <Column header="Informations Fichier">
                        <template #body="slotProps">
                            <div style="font-size:12px">Nom du fichier    : <i>{{ slotProps.data.nomFichierZip }}</i></div>
                            <div style="font-size:12px">Chemin du fichier : <i>{{ (slotProps.data.cheminFichierZip)}}</i></div>
                        </template>
                    </Column>
                    <Column header="Détail import sociétés">
                        <template #body="slotProps">
                            <!-- {{slotProps.data}} -->
                            <div><Tag severity="success" value="Ajouts" style="width: 55px;"></Tag> <b>{{slotProps.data.nombreSocietesAjoute}}</b></div>
                            <br/>
                            <div><Tag severity="secondary" value="Existants" style="width: 55px;"></Tag> <b>{{slotProps.data.nombreSocietesExistant}}</b></div>
                            <br/>
                            <div><Tag severity="danger" value="Erreurs" style="width: 55px;"></Tag> <b>{{slotProps.data.nombreSocietesErreur}}</b></div>
                        </template>
                    </Column>
                    <Column header="Détail import bilans">
                        <template #body="slotProps">
                            <div><Tag severity="success" value="Ajouts" style="width: 55px;"></Tag> <b>{{slotProps.data.nombreBilansAjoute}}</b></div>
                            <br/>
                            <div><Tag severity="secondary" value="Existants" style="width: 55px;"></Tag> <b>{{slotProps.data.nombreBilansExistant}}</b></div>
                            <br/>
                            <div><Tag severity="danger" value="Erreurs" style="width: 55px;"></Tag> <b>{{slotProps.data.nombreBilansErreur}}</b></div>
                        </template>
                    </Column>
                    <Column header="Message" field="messageErreurGlobal"></Column>
                    <template #expansion="slotProps">
                        <div class="p-3">
                            <DataTable :value="slotProps.data.detail">
                                <!-- <Column field="id" header="Id" sortable></Column> -->
                                <Column field="nomClient" header="Société traitée"  style="80px"></Column>
                                <Column field="messageErreurImportSociete" header="Détails" style="80px">
                                    <template #body="slotPropsDetail">
                                        <Tag v-if="slotPropsDetail.data.statutImportBilan == '1'" value="Ok" severity="success" ></Tag>
                                        <Tag v-else severity="info" value="Logs"></Tag>
                                        {{slotPropsDetail.data.messageErreurImportSociete}}
                                    </template>
                                </Column>
                            </DataTable>
                        </div>
                    </template>
                </DataTable>
            </div>

        </div>

        <!-- Delete Dialog -->


        <div class="errorMessage" ref="errorMessage"> {{errorMessage}}</div>
    </div>

    
    
</template>

<!-- ########################## <Script> ########################## -->
<script>
//--------------------------- Imports --------------------------
import AuthService from '../0-auth-components/auth.service';
import Header from "./Header.vue";
import CRUDService from "../1-common-components/crud.service";
import { Grid, GridColumn } from '@progress/kendo-grid-vue-wrapper';
import { KendoDataSource } from '@progress/kendo-datasource-vue-wrapper';
import kendo from '@progress/kendo-ui'
import {_} from 'vue-underscore';
import {FilterMatchMode,FilterOperator} from 'primevue/api';
import Dialog from 'primevue/dialog'
import Divider from 'primevue/divider'
import ConfirmDialog from 'primevue/confirmdialog';
import { Field, Form, ErrorMessage, } from 'vee-validate';
import * as yup from 'yup';
import Dropdown from 'primevue/dropdown';
import Password  from 'primevue/password';
import Calendar from 'primevue/calendar'

import FileUpload from 'primevue/fileupload';
import Button from 'primevue/button';

import Badge from 'primevue/badge';
import ProgressBar from 'primevue/progressbar';
import Tag from 'primevue/tag'

import authHeader from '../0-auth-components/auth-header.js';


export default {
    name: "AdminClients",
    //--------------------------- Components --------------------------
    components: {
        Header, 
        Dialog,
        Divider,
        ConfirmDialog,
        'kendo-grid': Grid,
        'kendo-grid-column': GridColumn,
        'kendo-datasource': KendoDataSource,
        'V-Field':Field,
        'V-Form':Form,
        ErrorMessage,
        Dropdown,
        Password,
        Calendar,
        FileUpload,
        Button,
        Badge,
        ProgressBar,
        Tag
    },
    //--------------------------- Data --------------------------
    data() {

        // Using yup to generate a validation schema
        // https://vee-validate.logaretm.com/v4/guide/validation#validation-schemas-with-yup
        const myValidationSchema = yup.object().shape({
                
                username: yup.string().max(50,"Maximum 50").required("Login est obligatoire !"),
                prenom: yup.string().max(50,"Maximum 50").required("Prénom est obligatoire !"),
                nom: yup.string().max(50,"Maximum 50").required("Nom est obligatoire !"),
                email: yup.string().email("Format email n'est pas valide").max(50,"Maximum 50").required("Email est obligatoire !"),
                
        });


        return {
            visible:false,
            eventFileUpload:null,
            yearInput:null,
            gridData: [],
            isImporting:false,
            columnsGrid:[
             'createur'
            ,'dateCreation'
            ,'annee'
            ,'nomFichierZip'
            ,'cheminFichierZip'
            ,'statutImportGlobal'
            ,'nombreSocietesAjoute'
            ,'nombreSocietesExistant'
            ,'nombreSocietesErreur'
            ,'nombreBilansAjoute'
            ,'nombreBilansExistant'
            ,'nombreBilansErreur'
            ,'dureeImportSecondes'
            ,'dateFinImport'
            ,'messageErreurGlobal'],
            showEditDialogFlag: false,
            selectedGridItem: {id:0},
            filters: {
                'global': {value: null, matchMode: FilterMatchMode.CONTAINS},
            },
            errorMessage: "",
            myValidationSchema,
            listeDesClients: [],
            clientRole:"Consultation",
            userPassword: "",
            expandedRows:[]

        } // end result
    }, //end data()
    //--------------------------- computed --------------------------
    computed: {
    },
    //--------------------------- Mounted --------------------------
    mounted() {
        // load logsImportOmpic
        this.yearInput = new Date()
        var ref = this
        CRUDService.listData('logsImportOmpic',{}).then(function(response){
                    if(response.data)
                    {
                        ref.gridData =  response.data;
                    }
                })
        this.log("----------- mounted()");
    }, //end mounted
    //--------------------------- Created --------------------------
    created() {
        this.log("----------- created()");
    },
    //--------------------------- Methods --------------------------
    methods: {
        hideHandler(value) { 
            if (!value) { 
                this.visible = false; 
            } 
        },
        assignEventGlobal(e)
        {
            console.log(e)
            this.eventFileUpload = e
        },
        onRowExpand(e)
        {
            console.log(e)
        },
        onTemplatedUpload(e)
        {
            var ref = this;
            this.isImporting = true;
            this.log(e)

            var file = e.files[0]; // Get the first file from the input element
            var formData = new FormData();
            formData.append('file', file);
            var headersObject = authHeader()
            
            // headersObject['Content-Type'] = 'multipart/form-data; boundary=----WebKitFormBoundary7MA4YWxkTrZu0gW'
            // headersObject['Content-Type'] = 'application/json'
            
            var apiUrl = process.env.VUE_APP_API_URL;

            var yearInputFormatted;
            if(this.yearInput instanceof Date && !isNaN(this.yearInput))
            {
                yearInputFormatted = this.yearInput.getFullYear()
            }
            else
            {
                yearInputFormatted = this.yearInput
            }

            fetch(apiUrl + 'UploadOmpicZip/' + yearInputFormatted, {
                method: 'POST',
                body: formData,
                headers:headersObject
            })
            .then(function(response){
                debugger;
                console.log(response)
                if(response.status != 200)
                {
                    ref.$toast.add({severity:'error', summary: 'Erreur', detail:response.statusText, life: 3000});
                }
                else
                {
                    ref.$toast.add({severity:'success', summary: 'Opération réussie', detail:"Import Ompic réussi", life: 3000});
                    return response.text()
                }
            })
            .then(data => {
                console.log('Success:', data);
                CRUDService.listData('logsImportOmpic',{}).then(function(response){
                            if(response.data)
                            {
                                ref.gridData =  response.data;
                                ref.isImporting = false;
                            }
                        })
            })
            .catch(error => {
                ref.isImporting = false;
                ref.$toast.add({severity:'error', summary: 'Opération non réussie', detail:"Import Ompic erroné", life: 3000});
                console.error('Error:', error);
            });
            
        }

        
    }
  
    //---------------------------
} //end export default
</script>

<!-- ########################## <Style> ########################## -->
<style >


label[for="createur"] {
    display: none;
}
</style>